.navbar {
  list-style-type: none;
  padding: 10px 0;
  margin: 0;
  display: flex;
  border-bottom: 1px solid black;
}

.navbar li a {
  padding: 10px;
  color: black;
  text-decoration: none;
}

.navbar li a.active {
  background-color: #e9e9ed;
}

.navbar li a:visited {
  color: black;
}
h1 {
  margin-top: 0;
}
.main {
  width: 500px;
  margin: auto;
  padding: 0 10px;
  height: calc(100vh - 41px);
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.feed {
  overflow: auto;
  height: calc(100% - 62px);
}

.post {
  background-color: #e9e9ed;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
}

.post-top {
  display: flex;
  justify-content: space-between;
}

.post-body {
  font-size: 2rem;
}

.form-item {
  display: flex;
  font-size: 1.2rem;
  margin-bottom: 10px;
  justify-content: space-between;
}
.form-item label {
  width: 20%;
}
.form-item input,
.form-item textarea {
  width: 80%;
}
.form-item input[type='submit'] {
  width: 100%;
  font-size: 1.5rem;
}
